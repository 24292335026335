export const githubIcon = `<svg viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/></svg>`;

export const linkedinIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382 382" xmlns:v="https://vecta.io/nano"><path d="M347.445 0h-312.9C15.47 0 0 15.47 0 34.555v312.89C0 366.53 15.47 382 34.555 382h312.89C366.53 382 382 366.53 382 347.444V34.555C382 15.47 366.53 0 347.445 0zM118.207 329.844a10.06 10.06 0 0 1-10.056 10.056H65.345a10.06 10.06 0 0 1-10.056-10.056v-179.44a10.06 10.06 0 0 1 10.056-10.056h42.806a10.06 10.06 0 0 1 10.056 10.056v179.44zm-31.46-206.412c-22.46 0-40.666-18.207-40.666-40.666S64.29 42.1 86.748 42.1s40.666 18.207 40.666 40.666-18.206 40.666-40.666 40.666zM341.9 330.654a9.25 9.25 0 0 1-9.246 9.246H286.73a9.25 9.25 0 0 1-9.246-9.246v-84.168c0-12.556 3.683-55.02-32.813-55.02-28.31 0-34.05 29.066-35.204 42.1v97.08a9.25 9.25 0 0 1-9.246 9.246h-44.426a9.25 9.25 0 0 1-9.246-9.246v-181.06a9.25 9.25 0 0 1 9.246-9.246h44.426a9.25 9.25 0 0 1 9.246 9.246v15.655c10.497-15.753 26.097-27.912 59.312-27.912 73.552 0 73.13 68.716 73.13 106.472v86.846z"/></svg>`;

export const steamIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xmlns:v="https://vecta.io/nano"><path d="M11.98 0A12 12 0 0 0 .022 11.037l6.432 2.658c.545-.37 1.203-.6 1.912-.6l.188.006 2.86-4.142V8.9a4.53 4.53 0 0 1 4.524-4.524c2.494 0 4.524 2.03 4.524 4.527a4.53 4.53 0 0 1-4.524 4.525h-.105l-4.076 2.91.004.16c0 1.875-1.515 3.396-3.4 3.396-1.635 0-3.016-1.173-3.33-2.727L.436 15.27C1.862 20.307 6.486 24 11.98 24c6.627 0 12-5.373 12-12s-5.373-12-12-12zM7.54 18.2l-1.473-.6a2.55 2.55 0 0 0 4.646-.125c.263-.63.264-1.32.005-1.95s-.75-1.12-1.377-1.383-1.3-.25-1.878-.03l1.523.63a1.88 1.88 0 0 1 1.009 2.455c-.397.957-1.497 1.4-2.454 1.012H7.54zm11.415-9.303a3.02 3.02 0 0 0-3.015-3.015 3.02 3.02 0 0 0-3.015 3.015c0 1.665 1.35 3.015 3.015 3.015s3.015-1.35 3.015-3.015zm-5.273-.005c0-1.252 1.013-2.266 2.265-2.266a2.27 2.27 0 0 1 2.266 2.266 2.27 2.27 0 0 1-2.266 2.265c-1.253 0-2.265-1.014-2.265-2.265z"/></svg>`;

export const soundcloudIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.319 291.319" xmlns:v="https://vecta.io/nano"><path d="M72.83 218.485h18.207V103.832c-6.828 1.93-12.982 5.435-18.207 10.04v104.612zM36.415 140.92v77.436l1.174.127h17.033v-77.682H37.59c0 .001-1.174.12-1.174.12zM0 179.63c0 14.102 7.338 26.328 18.207 33.147V146.52C7.338 153.33 0 165.556 0 179.63zm109.245 38.855h18.207v-109.6c-5.444-3.396-11.607-5.635-18.207-6.5v116.1zm144.485-77.682h-10.242c.52-3.168.847-6.382.847-9.705 0-32.182-25.245-58.264-56.388-58.264-16.896 0-31.954 7.775-42.287 19.955v125.695h108.07c20.747 0 37.59-17.388 37.59-38.855 0-21.447-16.842-38.826-37.59-38.826z"/></svg>`;

export const vscodemarketplaceIcon = `<svg viewBox="-11.9 -2 1003.9 995.6" xmlns="http://www.w3.org/2000/svg"><path d="m12.1 353.9s-24-17.3 4.8-40.4l67.1-60s19.2-20.2 39.5-2.6l619.2 468.8v224.8s-.3 35.3-45.6 31.4z"/><path d="m171.7 498.8-159.6 145.1s-16.4 12.2 0 34l74.1 67.4s17.6 18.9 43.6-2.6l169.2-128.3z"/><path d="m451.9 500 292.7-223.5-1.9-223.6s-12.5-48.8-54.2-23.4l-389.5 354.5z"/><path d="m697.1 976.2c17 17.4 37.6 11.7 37.6 11.7l228.1-112.4c29.2-19.9 25.1-44.6 25.1-44.6v-671.2c0-29.5-30.2-39.7-30.2-39.7l-197.7-95.3c-43.2-26.7-71.5 4.8-71.5 4.8s36.4-26.2 54.2 23.4v887.5c0 6.1-1.3 12.1-3.9 17.5-5.2 10.5-16.5 20.3-43.6 16.2z"/></svg>`;

export const bloggerIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:v="https://vecta.io/nano"><path d="M31.779 0C14.124 0 0 14.124 0 31.779V480.22C0 497.876 14.124 512 31.779 512H480.22c17.656 0 31.78-14.124 31.78-31.779V31.779C512 14.124 497.877 0 480.221 0H31.779zm159.779 97.104h80.332c31.78 0 81.213 31.778 81.213 79.447v44.139c47.669 0 61.793 19.421 61.793 67.09v31.779c0 54.731-38.841 95.338-94.455 95.338h-128c-56.497 0-95.338-40.607-95.338-95.338V192.441c0-55.614 38.841-95.338 94.455-95.338zm77.683 123.586H198.62c-12.359 0-22.069-9.71-22.069-22.069s9.71-22.069 22.069-22.069h70.621c12.359 0 22.069 9.71 22.069 22.069s-9.71 22.069-22.069 22.069m44.138 114.758H198.621c-12.359 0-22.069-9.71-22.069-22.069s9.71-22.069 22.069-22.069H313.38c12.359 0 22.069 9.71 22.069 22.069s-9.711 22.069-22.07 22.069"/></svg>`;

export const penupIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 22.5" xmlns:v="https://vecta.io/nano"><path d="M10.861.11c-.067.04-.302.437-.51.886l-.86 1.793-7.743 16.305c0 .329.154.537.423.584.121.02 1.625-.094 3.358-.255l3.143-.289 1.007 1.551 1.148 1.659c.161.141.645.161.813.034.06-.04.578-.792 1.142-1.659.98-1.498 1.041-1.578 1.229-1.565l3.21.295 3.21.188c.215-.087.322-.289.322-.591 0-.107-.672-1.578-1.551-3.405l-1.941-4.023-2.7-5.607-.826-1.712L11.693.184c-.101-.107-.201-.148-.423-.148-.161 0-.342.034-.41.074zm1.813 5.043c.114.141 6.339 13.095 6.319 13.142-.013.027-1.289-.067-2.834-.208-2.156-.201-2.861-.242-2.988-.188-.121.047-.457.497-1.027 1.377-.47.719-.88 1.31-.907 1.303-.027 0-.43-.578-.886-1.276-.463-.705-.907-1.33-.994-1.383-.134-.094-.376-.081-2.451.114-3.418.316-3.411.316-3.411.248 0-.034 1.168-2.485 2.599-5.44l3.123-6.494c.295-.611.564-1.148.604-1.195.054-.06.423-.081 1.43-.081s1.377.02 1.424.081z"/></svg>`;

export const credlyIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 7.938 7.938" xmlns:v="https://vecta.io/nano"><path d="M5.655.878c-.109.007-.233.074-.368.204-.226.218-.27.226-.487.122-1.236-.574-2.48.174-3.08 1.871-.235.67-.252 1.975-.044 2.567.348.948.931 1.41 1.793 1.418.94.009 2.027-.748 2.576-1.801.13-.25.255-.408.363-.468l-.022-.046-.032-.067c-.028-.052-.037-.063-.066-.105l-.074-.106-.044-.052c-.045-.048-.028-.032-.091-.091-.015-.015-.029-.031-.047-.043-.031-.021-.065-.038-.099-.056-.027-.014-.053-.03-.082-.039-.011-.003-.022-.005-.034-.008-.019.462-.345 1.026-.886 1.507-.618.566-1.262.705-1.862.426-.452-.218-.67-.644-.713-1.436-.096-1.714.8-3.176 1.845-2.976.131.026.305.148.374.261.122.183.131.278.044.6-.148.548-.131.801.061.905.252.131.496-.131.661-.74.078-.278.27-.748.418-1.027.2-.392.244-.548.174-.661S5.764.871 5.655.878z"/></svg>`;